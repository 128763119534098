(function($) { "use strict";	 

$('body').on('mouseenter mouseleave','.nav-item',function(e){
    if ($(window).width() > 750) {
        var _d=$(e.target).closest('.nav-item');_d.addClass('show');
        setTimeout(function(){
        _d[_d.is(':hover')?'addClass':'removeClass']('show');
        },1);
    }
});	

})(jQuery); 

    $('.companies-slider__wrapper').slick({
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      arrows: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {        
            slidesToShow: 4
          }
        },
        {
          breakpoint: 992,
          settings: {        
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {        
            slidesToShow: 2
          }
        },
        {
          breakpoint: 480,
          settings: {        
            slidesToShow: 1
          }
        }
      ]
    });
    
    $('.left-arrow-companies').click(function(){
        $('.companies-slider__wrapper').slick('slickPrev');
      });
      
      $('.right-arrow-companies').click(function(){
        $('.companies-slider__wrapper').slick('slickNext');
      });

      $('.news-slider-container__wrapper').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        responsive: [
      
          {
            breakpoint: 992,
            settings: {        
              slidesToShow: 2
            }
          },   
          {
            breakpoint: 768,
            settings: {        
              slidesToShow: 1
            }
          }
        ]
      });
      
      $('.left-arrow-news').click(function(){
          $('.news-slider-container__wrapper').slick('slickPrev');
        });
        
        $('.right-arrow-news').click(function(){
          $('.news-slider-container__wrapper').slick('slickNext');
        });


        var progressWidthWrap = $('.progress-wrapper').innerWidth();
        var summCounter = $('.positive').data('count') + $('.negative').data('count') + $('.neutral').data('count');
        $('.positive-counter p').html($('.positive').data('count'));
        $('.negative-counter p').html($('.negative').data('count'));
        $('.neutral-counter p').html($('.neutral').data('count'));
        $('.reviews-count-title span').html(summCounter);
        var oneHalf = progressWidthWrap / summCounter;
        var positiveCounts = (100 * (oneHalf * $('.positive').data('count'))) / progressWidthWrap;
        var negativeCounts = (100 * (oneHalf * $('.negative').data('count'))) / progressWidthWrap;
        var neutralCounts = (100 * (oneHalf * $('.neutral').data('count'))) / progressWidthWrap;
        $('.positive').css({
          'width': +positiveCounts + '%'
        });
        $('.negative').css({
          'width': +negativeCounts + '%'
        });
        $('.neutral').css({
          'width': +neutralCounts + '%'
        });

      $(".preview").click(function(){
        $("#upload").click();
      });
      
      $("#upload").change(function(){
        preview(this);
      });
      
      function preview(input) {
        if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.onload = function(e) {
            $('.preview').attr('src', e.target.result);
          };
          reader.readAsDataURL(input.files[0]);
        }
      }

      $('.star-cb-group > input').on('click', function(){
        $('#rating-5').removeClass('active-style');
        $('#rat-5').removeClass('active-style');
        var currVal = +($(this).val());

        if(currVal <= 3) {
          console.log(currVal);
          $('.status-rating').text('Отрицательный');
        }

        else if(currVal <= 7) {
          console.log(currVal);
          $('.status-rating').text('Нейтральный');
        }

        else if(currVal <= 10)  {
          console.log(currVal);
          $('.status-rating').text('Положительный');
         }            
      });
      var lengthVal = $('.star-cb-group > input').length;


$(document).ready(function () {

  var mTopofheader = $('.nav-menu-list').css('margin-top').split('p')[0]; // 70 or 40
  var mBottomofheader = $('.nav-menu-list').css('margin-bottom').split('p')[0];
  var headerH = $('.nav-menu-list').innerHeight();
  var headerHh = $('.nav-menu-container').innerHeight();
  var paddingSize =  +mTopofheader + +mBottomofheader + headerH + 'px';

  $(window).on('scroll', function() {
    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    if(width > 1024) {
      if ($(window).scrollTop() >= headerHh) {
        $('.nav-menu-list').addClass('header-scroll-start');
        $('body').css('paddingTop', paddingSize);

      } else {
        $('.nav-menu-list').removeClass('header-scroll-start');
        $('body').css('paddingTop', 0 + 'px' );
      }
    }
  });

});


// Init AOS
// AOS.init();